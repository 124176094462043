
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {required, requiredIf} from 'vuelidate/lib/validators';
import {AUTH_STORE_NAME} from "@/store/auth.store";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME, ApplicationStoreMutations} from "@/store/application.store";
import Company from "@/models/Company";
import {CorporateForm} from "@/enum/CorporateForm.enum";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import {AccountTitle} from "@/enum/AccountTitle.enum";
import {AccountPosition} from "@/enum/AccountPosition.enum";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

interface CorporateFormInterface {
  label: string,
  value: CorporateForm
}

@Component({
  mixins: [validationMixin],
  validations: {
    updatingCompany: {
      registrationNumber: {
        required: requiredIf(function (form: Company) {
          return form.corporateForm === CorporateForm.LIMITED_LIABILITY_COMPANY ||
          form.corporateForm === CorporateForm.PARTNERSHIP;
        })
      },
      contact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        title: {
          required
        },
        position: {
          required
        },
      }
    }
  }
})
export default class EditCompanyDetailsComponent extends mixins(ErrorMessageHandlerMixin) {
  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_COMPANY)
  private setCompany!: (company: Company) => void;

  @Prop({required: true})
  private company!: Company;

  /**
   * flag that indicates if the registerNumber should be shown
   */
  private showNumber = false;

  /**
   * copy of the active company
   */
  private updatingCompany: Company = Company.parseFromObject({});

  /**
   * is called when the corporate form has changed, updates the flag
   */
  private onCorporateFormChanged(value: any) {
    this.showNumber = value === CorporateForm.LIMITED_LIABILITY_COMPANY ||
        value === CorporateForm.PARTNERSHIP;
    this.$v.updatingCompany.registrationNumber?.$reset();
  }

  created() {
    this.updatingCompany = Company.parseFromObject({...this.company});
    this.onCorporateFormChanged(this.updatingCompany.corporateForm);
  }

  /**
   * different options of titles for the user
   * @private
   */
  private titleOptions = [
    { value: AccountTitle.MALE, label: this.$t('GENERAL.TITLES.MALE')},
    { value: AccountTitle.FEMALE, label: this.$t('GENERAL.TITLES.FEMALE')},
    { value: AccountTitle.DIVERSE, label: this.$t('GENERAL.TITLES.DIVERSE')},
  ]

  /**
   * different options of the position for the user
   * @private
   */
  private positionOptions = [
    { value: AccountPosition.MANAGER, label: this.$t('GENERAL.POSITIONS.MANAGER')},
    { value: AccountPosition.OWNER, label: this.$t('GENERAL.POSITIONS.OWNER')},
    { value: AccountPosition.EMPLOYEE, label: this.$t('GENERAL.POSITIONS.EMPLOYEE')},
    { value: AccountPosition.OTHER, label: this.$t('GENERAL.POSITIONS.OTHER')},
  ]

  /**
   * flag used for the check box to indicate if the user chose taxFree
   */
  private enableTaxFreeOption: boolean = false;

  /**
   * different options of titles for the user
   * @private
   */
  public formOptions: CorporateFormInterface[] = [
    { value: CorporateForm.SOLE_TRADER, label: this.$t('GENERAL.FORMS.SOLE_TRADER').toString()},
    { value: CorporateForm.LIMITED_LIABILITY_COMPANY, label: this.$t('GENERAL.FORMS.LIMITED_LIABILITY_COMPANY').toString()},
    { value: CorporateForm.PARTNERSHIP, label: this.$t('GENERAL.FORMS.PARTNERSHIP').toString()},
  ];

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;
      const response = await CompanyRepository.updateCompany(this.updatingCompany.id, this.updatingCompany);
      const updatedCompany = Company.parseFromObject({...response.data});
      this.setCompany(updatedCompany);

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_COMPANY_DATA_SUCCESS');
      this.dismiss(true);
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_COMPANY_DATA_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
